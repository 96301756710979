@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

.wedding-title {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 2rem;
    border-bottom: 1px solid #000000;
}
.wedding-column {
    padding: 0.4em;
}

.wedding-img {
    margin-bottom: 1em;
}


