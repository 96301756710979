.white-background {
    background: #ffffff;
}

.vanilla-background {
    background: #fafafa;
}

.spaced-top {
    padding-top: 2em;
}

.spaced-bottom {
    padding-top: 2em;
}

.section {
    margin-top: 0;
}

.icon {
    height: 1.5em;
    width: 1.5em;
    margin-bottom: 0.7em;
}
.icon-big {
     height: 3em;
     width: 3em;
 }
