.section {
    margin-bottom: 2em;
    padding: 2em;
}

.card-texts {
    padding-right: 2em;
    padding-left: 2em;
}

.shadow-image {
    border-radius: 50%;
    box-shadow: 0 1em 1em #00000088;
}