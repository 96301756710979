@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Photograp-Signature';
    src: url(/static/media/Photograp-Signature.fd3e04eb.ttf) format('truetype');
}

.footer {
    border-top: 1px solid #3db3b344;
    background-color: #fcfcfc;
    padding-top: 20px;
}

.social-icon {
    height: 20px;
    width: 20px;
    margin: 5px 20px 5px 20px;
}

.copyright {
    font-family: 'Dancing Script', cursive;
    font-size: 1.2em;
    padding: 20px;
}

@font-face {
    font-family: 'Photograp-Signature';
    src: url(/static/media/Photograp-Signature.fd3e04eb.ttf) format('truetype');
}

@font-face {
    font-family: 'Gabriola';
    src: url(/static/media/Gabriola.88ea98ef.ttf) format('truetype');
}

.title {
    font-family: 'Photograp-Signature';
    color: #323232;
    font-size: 5rem;
    margin-top: 0.25em;
    margin-bottom: 1em;
    border-bottom: 1px solid #000000;
}

.headline {
    font-family: 'Gabriola';
    color: #000000;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 3em;
}

.sub-category-underlined {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 4rem;
    border-bottom: 1px solid #000000;
}

.sub-category {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 4rem;
}

.link-text {
    font-family: 'Gabriola';
    color: #c8731e;
    font-size: 1.4rem;
}

.content {
    font-family: 'Gabriola';
    color: #000000;
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 0em;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}
.main-carousel {
    margin-top: 50px;
    margin-bottom: 50px;
}

.main-carousel-img {
    border: 0px;
}
.white-background {
    background: #ffffff;
}

.vanilla-background {
    background: #fafafa;
}

.spaced-top {
    padding-top: 2em;
}

.spaced-bottom {
    padding-top: 2em;
}

.section {
    margin-top: 0;
}

.icon {
    height: 1.5em;
    width: 1.5em;
    margin-bottom: 0.7em;
}
.icon-big {
     height: 3em;
     width: 3em;
 }



.section {
    margin-bottom: 2em;
    padding: 2em;
}

.card-texts {
    padding-right: 2em;
    padding-left: 2em;
}

.shadow-image {
    border-radius: 50%;
    box-shadow: 0 1em 1em #00000088;
}

.section {
    margin-bottom: 2em;
    padding: 2em;
}

.card-texts {
    padding-right: 2em;
    padding-left: 2em;
}

.shadow-image {
    border-radius: 50%;
    box-shadow: 0 1em 1em #00000088;
}
.wedding-title {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 2rem;
    border-bottom: 1px solid #000000;
}
.wedding-column {
    padding: 0.4em;
}

.wedding-img {
    margin-bottom: 1em;
}



.bit-padding {
    border-radius: 3em;
}
.form-text {
    font-family: 'Tangerine', cursive;
    font-size: 2rem;
    color: #222222;
    font-weight: 700;
}

.form-value {
    font-size: 1rem;
    color: #c8731e;
    font-weight: 200;
    border: 1px solid #c8731e;
}

.form-textarea {
    margin-top: 1em;
}

.form-button {
    font-family: 'Tangerine', cursive;
    color: #222222;
    font-size: 2rem;
    font-weight: 700;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #c8731e;
    border-radius: 1em;

    margin-top: 2em;
    margin-bottom: 1em;
}

.form-field {
    margin-bottom: 3em;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.gallery-carousel-img {
    margin-top: 2em;
}
.wedding-banner {
    margin-bottom: 5em;
}
@font-face {
    font-family: 'Gabriola';
    src: url(/static/media/Gabriola.88ea98ef.ttf) format('truetype');
}


.navigation-bar {
    border-bottom: 1px solid #00000033;
    padding-bottom: 1em;
}

.navigation-logo {
    padding-top: 4em;
    padding-bottom: 6em;
}

.navigation-button {
    width: 1.5em;
}

.navigation-link {
    font-family: 'Gabriola';
    font-size: 1.7rem;
    color: #222222;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.underline {
    background-color: #cfa9ba;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    position: absolute;
    bottom: -0.5em;
}

.splatters {
    width: 1.5em;
    height: 1.5em;
    padding-left: 0.5em;
}

.selected_option {
    background: url(/static/media/ManchasDoradasMini.1c62337d.png);
    background-size: contain;
    height: 1em;
}

a:hover {
    color: #ffc842;
    text-decoration: none;
}
