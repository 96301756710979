@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

.form-text {
    font-family: 'Tangerine', cursive;
    font-size: 2rem;
    color: #222222;
    font-weight: 700;
}

.form-value {
    font-size: 1rem;
    color: #c8731e;
    font-weight: 200;
    border: 1px solid #c8731e;
}

.form-textarea {
    margin-top: 1em;
}

.form-button {
    font-family: 'Tangerine', cursive;
    color: #222222;
    font-size: 2rem;
    font-weight: 700;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #c8731e;
    border-radius: 1em;

    margin-top: 2em;
    margin-bottom: 1em;
}

.form-field {
    margin-bottom: 3em;
}