@font-face {
    font-family: 'Gabriola';
    src: url('../../fonts/Gabriola.ttf') format('truetype');
}


.navigation-bar {
    border-bottom: 1px solid #00000033;
    padding-bottom: 1em;
}

.navigation-logo {
    padding-top: 4em;
    padding-bottom: 6em;
}

.navigation-button {
    width: 1.5em;
}

.navigation-link {
    font-family: 'Gabriola';
    font-size: 1.7rem;
    color: #222222;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.underline {
    background-color: #cfa9ba;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    position: absolute;
    bottom: -0.5em;
}

.splatters {
    width: 1.5em;
    height: 1.5em;
    padding-left: 0.5em;
}

.selected_option {
    background: url("../../img/navbar/ManchasDoradasMini.png");
    background-size: contain;
    height: 1em;
}

a:hover {
    color: #ffc842;
    text-decoration: none;
}