@font-face {
    font-family: 'Photograp-Signature';
    src: url('../../fonts/Photograp-Signature.ttf') format('truetype');
}

@font-face {
    font-family: 'Gabriola';
    src: url('../../fonts/Gabriola.ttf') format('truetype');
}

.title {
    font-family: 'Photograp-Signature';
    color: #323232;
    font-size: 5rem;
    margin-top: 0.25em;
    margin-bottom: 1em;
    border-bottom: 1px solid #000000;
}

.headline {
    font-family: 'Gabriola';
    color: #000000;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 3em;
}

.sub-category-underlined {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 4rem;
    border-bottom: 1px solid #000000;
}

.sub-category {
    font-family: 'Photograp-Signature';
    color: #c8731e;
    font-size: 4rem;
}

.link-text {
    font-family: 'Gabriola';
    color: #c8731e;
    font-size: 1.4rem;
}

.content {
    font-family: 'Gabriola';
    color: #000000;
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 0em;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}