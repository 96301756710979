@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'Photograp-Signature';
    src: url('../../fonts/Photograp-Signature.ttf') format('truetype');
}

.footer {
    border-top: 1px solid #3db3b344;
    background-color: #fcfcfc;
    padding-top: 20px;
}

.social-icon {
    height: 20px;
    width: 20px;
    margin: 5px 20px 5px 20px;
}

.copyright {
    font-family: 'Dancing Script', cursive;
    font-size: 1.2em;
    padding: 20px;
}
